// property页面和generation所需数据
const representationData = [
  // 2024年11月25日 representation v1.1.0版本有16个模型
    {
        value: 'fixed',
        label: 'Fixed Representation',
        children: [
            { value: 'Day_light', label: 'Daylight' },
            { value: 'ECFP2', label: 'ECFP2' },
            { value: 'ECFP4', label: 'ECFP4'},
            { value: 'ECFP6', label: 'ECFP6'},
            { value: 'MACCS', label: 'MACCS'},
            { value: 'Pubchem', label: 'PubChem'},
            { value: 'Avalon', label: 'AVALON'},
            { value: 'RDK2d', label: 'RDKit2d'},
            { value: 'Mordred', label: 'Mordred'},
            // { value: '3DFP', label: '3DFP'},
            // { value: 'E3FP', label: 'E3FP'},
            // { value: 'SELFIES', label: 'SELFIES'}
        ]
    },
    {
        value: 'learned',
        label: 'Learned Representation',
        children: [
            // { value: 'BAN', label: 'BAN' },
            // { value: 'molmapnet', label: 'MolMapNet' }, // 流程选项里没有
            // { value: 'AttentiveFP', label: 'AttentiveFP'},
            // { value: 'TrimNet', label: 'TrimNet'},
            // { value: 'GCN', label: 'GCN'},
            // { value: 'GIN', label: 'GIN'},
            { value: 'MolCLR', label: 'MolCLR'},
            // { value: 'ImageMol', label: 'ImageMol'},
            // { value: 'SphereNet', label: 'SphereNet'},
            { value: 'GraphMVP', label: 'GraphMVP'},
            // { value: 'Infomax', label: '3d Infomax'},
            { value: 'UniMol', label: 'UniMol'},
            { value: 'KANO', label: 'KANO'},
            { value: 'KPGT', label: 'KPGT'},
            { value: 'MoLFormer', label: 'MoLFormer'},
            // { value: 'PharmHGT', label: 'PharmHGT'},
            { value: 'PremuNet', label: 'PremuNet'}
        ]
    }
]
const predictRepresentation = [
  // 26个模型
    {
        value: 'fixed',
        label: 'Fixed Representation',
        children: [
            { value: 'Day_light', label: 'Daylight' },
            { value: 'ECFP2', label: 'ECFP2' },
            { value: 'ECFP4', label: 'ECFP4'},
            { value: 'ECFP6', label: 'ECFP6'},
            { value: 'MACCS', label: 'MACCS'},
            { value: 'Pubchem', label: 'PubChem'},
            { value: 'Avalon', label: 'AVALON'},
            { value: 'RDK2d', label: 'RDKit2d'},
            { value: 'Mordred', label: 'Mordred'},
            // { value: '3dfp', label: '3DFP'},
            { value: 'E3FP', label: 'E3FP'},
            // { value: 'selfies', label: 'SELFIES'}
        ]
    },
    {
        value: 'learned',
        label: 'Learned Representation',
        children: [
            // { value: 'ban', label: 'BAN' },
            // { value: 'molmapnet', label: 'MolMapNet' },
            { value: 'attentivefp', label: 'AttentiveFP'},
            // { value: 'trimnet', label: 'TrimNet'},
            { value: 'gcn', label: 'GCN'},
            { value: 'gin', label: 'GIN'},
            { value: 'molclr', label: 'MolCLR'},
            { value: 'imagemol', label: 'ImageMol'},
            { value: 'spherenet', label: 'SphereNet'},
            { value: 'graphmvp', label: 'GraphMVP'},
            { value: '3dinfomax', label: '3d Informax'},
            { value: 'UniMol', label: 'UniMol'},
            { value: 'KANO', label: 'KANO'},
            { value: 'KPGT', label: 'KPGT'},
            { value: 'MoLFormer', label: 'MoLFormer'},
            { value: 'PharmHGT', label: 'PharmHGT'},
            { value: 'PremuNet', label: 'PremuNet'}
        ]
    }
]
const tipMap = new Map([
    ['representation', "Note: It is not allowed to select options under both Fixed and Learned categories simultaneously."],
    ['repreChoose', "Tips: Please select a characterization method"],
    ['smiles', "Tips: Please upload a file or enter the SMILES"],
    ['model', 'Note: Please select a model when choosing a fixed representation']
])
const driverDescriptionMap = new Map([
    ['representation-driver', 'Select the type of representation method such as Fixed or Learned. <br /> Note that you can only select one category' +
    ' When selecting a Fixed representation, you can proceed to choose a model. <br />When selecting a Learned representation, you will proceed to the task selection.'],
    ['smiles', "Tips: Please Upload a file or Enter the SMILES"],
    ['smiles-driver', 'In this step, you can either input a SMILES string or upload a file. <br /> Click "Next" to proceed.'],
    ['model', 'Tips: Select models. This option is only available when you choose a Fixed representation.'],
    ['email', 'Please enter your email address. If no entry is provided, a default demonstration email will be automatically used.'],
    ['result', 'Wait for the results to be generated, displayed and available for download. <br />Click "Done" to complete the process.'],
])
const modelOptionsData = [
    { value: 'SVM', label: 'SVM' },
    { value: 'RF', label: 'RF'},
    { value: 'XGBoost', label: 'XGB'},
    { value: 'DNN', label: 'DNN'},
]
const taskOptionsData = [
    {
        value: 'Physiology',
        label: 'Physiology',
        children: [
            {value: 'bbbp', label: 'BBBP'},
            {value: 'tox21', label: 'Tox21'},
            {value: 'sider', label: 'SIDER'},
            {value: 'toxcast', label: 'ToxCast'},
            {value: 'clintox', label: 'ClinTox'},
        ]
    },
    {
        value: 'Biophysics',
        label: 'Biophysics',
        children: [
            {value: 'bace', label: 'BACE'},
            {value: 'hiv', label: 'HIV'},
            {value: 'muv', label: 'MUV'}
        ]
    },
    {
        value: 'Physical chemistry',
        label: 'Physical Chemistry',
        children: [
            {value: 'esol', label: 'ESOL'},
            {value: 'freesolv', label: 'FreeSolv'},
            {value: 'lipophilicity', label: 'Lipophilicity'} // 国科上：lip
        ]
    },
    {
        value: 'Quantum mechanics',
        label: 'Quantum Mechanics',
        children: [
            {value: 'qm8', label: 'QM8'},
            {value: 'qm9', label: 'QM9'}
        ]
    },
    {
        value: 'SARS-CoV-2',
        label: 'SARS-CoV-2',
        children: [
            {value: 'hek293', label: 'HEK293'}, // 国科上是hek
            {value: 'cov-ppe', label: 'CoV-PPE'}, // 国科上：SARS_PPE
            {value: 'cpe', label: 'CPE'}, // 国科上：SARE_CPE
            {value: 'alphalisa', label: 'AlphaLISA'},
        ]
    },
]
const dialogData = {
    title: 'Guidance for choosing the appropriate representation method',
    list: [
        {
            key: 1,
            name: 'general',
            question: 'Do you need a general representation?',
            choices: ['YES', 'NO'],
            valueCondition: {
                currentChoice: 'YES', // 当前问题的选项
                result: ['KPGT', 'KANO', 'MoLFormer', 'AVALON', 'RDKit2d']
            }
        },
        {
            key: 2,
            name: 'properties',
            question: 'What category of properties of the compound do you want to predict?',
            choices: ['Physiology', 'Biophysics', 'Physical Chemistry', 'Quantum Mechanics', 'SARS-CoV-2'],
            disabled: { // 当key为1的问题选择NO时，该问题才显示；否则都是false隐藏
                key: 1,
                value: 'NO'
            }
        },
        // {
        //     key: 3,
        //     name: 'representation',
        //     question: 'There are many different properties in each category of prediction properties, and whether you need a representation that is generic across multiple properties or one that applies to a specific property？',
        //     choices: ['General Representation', 'Specific Representation'],
        //     valueCondition: {
        //         key: 2,
        //         result: [
        //             {
        //                 value: 'Physiology',
        //                 representation: [
        //                     ['General Representation', ['AVALON', 'RDKit2d']]
        //                 ]
        //             },
        //             {
        //                 value: 'Biophysics',
        //                 representation: [
        //                     ['General Representation', ['ECFP4', 'KPGT']]
        //                 ]
        //             },
        //             {
        //                 value: 'Physical Chemistry',
        //                 representation: [
        //                     ['General Representation',  ['KANO']] // 'BAN',
        //                 ]
        //             },
        //             {
        //                 value: 'Quantum Mechanics',
        //                 representation: [
        //                     ['General Representation', ['UniMol', 'PharmHGT', 'MoLFormer']] // 'BAN',
        //                 ]
        //             }
        //         ]
        //     },
        //     disabled: {
        //         key: 1,
        //         value: 'NO'
        //     }
        // },
        {
            key: 3,
            name: 'task',
            question: 'In this prediction task, which property of the compound do you want to predict more?',
            choices: ['BBBP', 'Tox21', 'SIDER', 'ToxCast', 'ClinTox', 'General/Universal'],
            choicesCondition: [
                { key: '2', value: 'Physiology', choices: ['BBBP', 'Tox21', 'SIDER', 'ToxCast', 'ClinTox', 'General/Universal'] },
                { key: '2', value: 'Biophysics', choices: ['BACE', 'HIV', 'MUV', 'General/Universal'] },
                { key: '2', value: 'Physical Chemistry', choices: ['ESOL', 'FreeSolv', 'Lipophilicity', 'General/Universal'] },
                { key: '2', value: 'Quantum Mechanics', choices: ['QM8', 'QM9', 'General/Universal'] },
                { key: '2', value: 'SARS-CoV-2', choices: ['HEK293', 'CoV-PPE', 'CPE', 'AlphaLISA', 'General/Universal'] },
            ],
            valueCondition: {
                key: 2,
                result: [
                    {
                        value: 'Physiology', // key为2的选项的选中value
                        representation: [
                            ['BBBP', ['KANO', 'PharmHGT']], // representation[0][0]即为当前题目的选中value
                            ['ClinTox', ['PremuNet', 'AVALON']],
                            ['SIDER', ['E3FP', 'ECFP2', 'MACCS']],
                            ['Tox21', ['UniMol', 'KPGT', 'AttentiveFP']],
                            ['ToxCast', ['UniMol', 'TrimNet', 'AVALON']],
                            ['General/Universal', ['AVALON', 'RDKit2d']],
                        ]
                    },
                    {
                        value: 'Biophysics',
                        representation: [
                            ['BACE', ['Daylight', 'Mordred']],
                            ['HIV', ['UniMol', 'KPGT']],
                            ['MUV', ['ECFP4', 'AVALON', 'KANO']],
                            ['General/Universal', ['ECFP4', 'KPGT']],
                        ]
                    },
                    {
                        value: 'Physical Chemistry',
                        representation: [
                            ['ESOL', ['RDKit2d']],
                            ['FreeSolv', ['RDKit2d', 'UniMol']],
                            ['Lipophilicity', ['UniMol','KPGT']],
                            ['General/Universal', ['KANO']],
                        ]
                    },
                    {
                        value: 'Quantum Mechanics',
                        representation: [
                            ['QM8', ['UniMol', 'KPGT', 'KANO']],
                            ['QM9', ['UniMol', 'KPGT', 'PharmHGT']],
                            ['General/Universal', ['UniMol', 'PharmHGT', 'MoLFormer']],
                        ]
                    },
                ]
            },
            disabled: {
                key: 1,
                value: 'NO'
            }
        },
        {
            key: 4,
            name: 'metrics',
            question: 'What kind of metrics do you want to choose?',
            choices: ['Unified Score', 'Precision'],
            showCondition: {
                key: 2,
                name: 'properties',
                value: 'SARS-CoV-2'
            },
            valueCondition: {
                key: 3, // 取决与上一个问题的value
                result: [
                    {
                        value: 'HEK293', // 上一个问题的具体的value
                        representation: [
                            ['Unified Score', ['ECFP4', 'Mordred', 'Molformer']], // [当前选择的value, 对应的表征]
                            ['Precision', ['KPGT', 'ECFP4', 'ECFP6']]
                        ]
                    },
                    {
                        value: 'CoV-PPE',
                        representation: [
                            ['Unified Score', ['AVALON', 'TrimNet', 'MACCS']],
                            ['Precision', ['AVALON', 'ImageMol', 'SELFIES']]
                        ]
                    },
                    {
                        value: 'CPE',
                        representation: [
                            ['Unified Score', ['KPGT']],
                            ['Precision', ['SELFIES']]
                        ]
                    },
                    {
                        value: 'AlphaLISA',
                        representation: [
                            ['Unified Score', ['UniMol']],
                            ['Precision', ['AVALON','KPGT']]
                        ]
                    },
                    {
                        value: 'General/Universal', // 上一个问题的具体的value
                        representation: [
                            ['Unified Score', ['KPGT']], // [当前选择的value, 对应的表征]
                            ['Precision', ['AVALON', 'ECFP4', 'ECFP6']]
                        ]
                    },
                ]
            },
        },
    ]
}
const analysisStatusMap = new Map([
      [1, 'Waiting for the Resource to Load...'],
      [2, 'The Analysis Task is Running...'],
      [3, 'The Analysis Was Successful！！！'],
      [4, 'Analysis Failed！！！']
  ])
const resultsCodeMap = new Map([
      [1, 'info'],
      [2, 'info'],
      [3, 'success'],
      [4, 'error']
  ])
const needMoreCpuModel = ['PremuNet']

const tipsWithDatasets = new Map([
    ['MUV', ['0: Inactive', '1: Active']],
    ['HIV', ['0: Inactive', '1: Active']],
    ['BACE', ['0: Inactive', '1: Active']],
    ['BBBP', ['0: Non-Penetrant', '1: Penetrant']],
    ['Tox21', ['0: Inactive', '1: Active']],
    ['ToxCast', ['0: Inactive', '1: Active']],
    ['SIDER', ['0: Not-Cause', '1: Cause']],
    ['ClinTox', ['0: Not-Toxic', '1: Toxic']],
    ['HEK293', ['0: Non-Inhibitor', '1: Inhibitor']],
    ['CPE', ['0: Non-Inhibitor', '1: Inhibitor']],
    ['CoV-PPE', ['0: Non-Inhibitor', '1: Inhibitor']],
    ['AlphaLISA', ['0: Non-Inhibitor', '1: Inhibitor']]
])
const unlineTasks = new Map([
    ['SARS_PPE', 'CoV-PPE'],
    ['SARS_CPE', 'CPE'],
    ['lip', 'Lipophilicity']
])
export {
    needMoreCpuModel,
    representationData,
    tipMap,
    modelOptionsData,
    taskOptionsData,
    dialogData,
    analysisStatusMap,
    resultsCodeMap,
    driverDescriptionMap,
    predictRepresentation,
    tipsWithDatasets,
    unlineTasks
}
