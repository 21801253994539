export const codes = {
  "Afghanistan": "AF",
  "Aland Islands": "AX",
  "Albania": "AL",
  "Algeria": "DZ",
  "American Samoa": "AS",
  "Andorra": "AD",
  "Angola": "AO",
  "Anguilla": "AI",
  "Antarctica": "AQ",
  "Antigua and Barbuda": "AG",
  "Argentina": "AR",
  "Armenia": "AM",
  "Aruba": "AW",
  "Australia": "AU",
  "Austria": "AT",
  "Azerbaijan": "AZ",
  "Bahamas (The)": "BS",
  "Bahrain": "BH",
  "Bangladesh": "BD",
  "Barbados": "BB",
  "Belarus": "BY",
  "Belgium": "BE",
  "Belize": "BZ",
  "Benin": "BJ",
  "Bermuda": "BM",
  "Bhutan": "BT",
  "Bolivia": "BO",
  "Bosnia and Herzegovina": "BA",
  "Botswana": "BW",
  "Bouvet Island": "BV",
  "Brazil": "BR",
  "British Indian Ocean Territory (the)": "IO",
  "Brunei Darussalam": "BN",
  "Bulgaria": "BG",
  "Burkina Faso": "BF",
  "Burundi": "BI",
  "Cambodia": "KH",
  "Cameroon": "CM",
  "Canada": "CA",
  "Cape Verde": "CV",
  "Cayman Islands (the)": "KY",
  "Central African Republic (the)": "CF",
  "Chad": "TD",
  "Chile": "CL",
  "China": "CN",
  "Christmas Island": "CX",
  "Cocos (Keeling) Islands (the)": "CC",
  "Colombia": "CO",
  "Comoros": "KM",
  "Congo": "CG",
  "Congo (the Democratic Republic of the)": "CD",
  "Cook Islands (the)": "CK",
  "Costa Rica": "CR",
  "Côte d'Ivoire": "CI",
  "Croatia": "HR",
  "Cuba": "CU",
  "Cyprus": "CY",
  "Czech Republic (the)": "CZ",
  "Denmark": "DK",
  "Djibouti": "DJ",
  "Dominica": "DM",
  "Dominican Republic (the)": "DO",
  "Ecuador": "EC",
  "Egypt": "EG",
  "El Salvador": "SV",
  "Equatorial Guinea": "GQ",
  "Eritrea": "ER",
  "Estonia": "EE",
  "Ethiopia": "ET",
  "Falkland Islands (the) [Malvinas]": "FK",
  "Faroe Islands (the)": "FO",
  "Fiji": "FJ",
  "Finland": "FI",
  "France": "FR",
  "French Guiana": "GF",
  "French Polynesia": "PF",
  "French Southern Territories (the)": "TF",
  "Gabon": "GA",
  "Gambia (The)": "GM",
  "Georgia": "GE",
  "Germany": "DE",
  "Ghana": "GH",
  "Gibraltar": "GI",
  "Greece": "GR",
  "Greenland": "GL",
  "Grenada": "GD",
  "Guadeloupe": "GP",
  "Guam": "GU",
  "Guatemala": "GT",
  "Guernsey": "GG",
  "Guinea": "GN",
  "Guinea-Bissau": "GW",
  "Guyana": "GY",
  "Haiti": "HT",
  "Heard Island and McDonald Islands": "HM",
  "Holy See (the) [Vatican City State]": "VA",
  "Honduras": "HN",
  "Hong Kong": "HK",
  "Hungary": "HU",
  "Iceland": "IS",
  "India": "IN",
  "Indonesia": "ID",
  "Iran": "IR",
  "Iraq": "IQ",
  "Ireland": "IE",
  "Isle of Man": "IM",
  "Israel": "IL",
  "Italy": "IT",
  "Jamaica": "JM",
  "Japan": "JP",
  "Jersey": "JE",
  "Jordan": "JO",
  "Kazakhstan": "KZ",
  "Kenya": "KE",
  "Kiribati": "KI",
  "Korea (the Democratic People's Republic of)": "KP",
  "Korea (the Republic of)": "KR",
  "Kuwait": "KW",
  "Kyrgyzstan": "KG",
  "Lao People's Democratic Republic (the)": "LA",
  "Latvia": "LV",
  "Lebanon": "LB",
  "Lesotho": "LS",
  "Liberia": "LR",
  "Libyan Arab Jamahiriya (the)": "LY",
  "Liechtenstein": "LI",
  "Lithuania": "LT",
  "Luxembourg": "LU",
  "Macao": "MO",
  "Macedonia (the former Yugoslav Republic of)": "MK",
  "Madagascar": "MG",
  "Malawi": "MW",
  "Malaysia": "MY",
  "Maldives": "MV",
  "Mali": "ML",
  "Malta": "MT",
  "Marshall Islands (the)": "MH",
  "Martinique": "MQ",
  "Mauritania": "MR",
  "Mauritius": "MU",
  "Mayotte": "YT",
  "Mexico": "MX",
  "Micronesia (the Federated States of)": "FM",
  "Moldova (the Republic of)": "MD",
  "Monaco": "MC",
  "Mongolia": "MN",
  "Montenegro": "ME",
  "Montserrat": "MS",
  "Morocco": "MA",
  "Mozambique": "MZ",
  "Myanmar": "MM",
  "Namibia": "NA",
  "Nauru": "NR",
  "Nepal": "NP",
  "Netherlands (the)": "NL",
  "Netherlands Antilles (the)": "AN",
  "New Caledonia": "NC",
  "New Zealand": "NZ",
  "Nicaragua": "NI",
  "Niger (the)": "NE",
  "Nigeria": "NG",
  "Niue": "NU",
  "Norfolk Island": "NF",
  "Northern Mariana Islands (the)": "MP",
  "Norway": "NO",
  "Oman": "OM",
  "Pakistan": "PK",
  "Palau": "PW",
  "Palestinian Territory (the Occupied)": "PS",
  "Panama": "PA",
  "Papua New Guinea": "PG",
  "Paraguay": "PY",
  "Peru": "PE",
  "Philippines (the)": "PH",
  "Pitcairn": "PN",
  "Poland": "PL",
  "Portugal": "PT",
  "Puerto Rico": "PR",
  "Qatar": "QA",
  "Réunion": "RE",
  "Romania": "RO",
  "Russian Federation (the)": "RU",
  "Rwanda": "RW",
  "Saint Helena": "SH",
  "Saint Kitts and Nevis": "KN",
  "Saint Lucia": "LC",
  "Saint Pierre and Miquelon": "PM",
  "Saint Vincent and the Grenadines": "VC",
  "Samoa": "WS",
  "San Marino": "SM",
  "Sao Tome and Principe": "ST",
  "Saudi Arabia": "SA",
  "Senegal": "SN",
  "Serbia": "RS",
  "Seychelles": "SC",
  "Sierra Leone": "SL",
  "Singapore": "SG",
  "Slovakia": "SK",
  "Slovenia": "SI",
  "Solomon Islands (the)": "SB",
  "Somalia": "SO",
  "South Africa": "ZA",
  "South Georgia and the South Sandwich Islands": "GS",
  "Spain": "ES",
  "Sri Lanka": "LK",
  "Sudan (the)": "SD",
  "Suriname": "SR",
  "Svalbard and Jan Mayen": "SJ",
  "Swaziland": "SZ",
  "Sweden": "SE",
  "Switzerland": "CH",
  "Syrian Arab Republic (the)": "SY",
  "Taiwan (Province of China)": "TW",
  "Tajikistan": "TJ",
  "Tanzania,United Republic of": "TZ",
  "Thailand": "TH",
  "Timor-Leste": "TL",
  "Togo": "TG",
  "Tokelau": "TK",
  "Tonga": "TO",
  "Trinidad and Tobago": "TT",
  "Tunisia": "TN",
  "Turkey": "TR",
  "Turkmenistan": "TM",
  "Turks and Caicos Islands (the)": "TC",
  "Tuvalu": "TV",
  "Uganda": "UG",
  "Ukraine": "UA",
  "United Arab Emirates (the)": "AE",
  "United Kingdom (the)": "GB",
  "United States": "US",
  "United States Minor Outlying Islands (the)": "UM",
  "Uruguay": "UY",
  "Uzbekistan": "UZ",
  "Vanuatu": "VU",
  "Venezuela": "VE",
  "Viet Nam": "VN",
  "Virgin Islands (British)": "VG",
  "Virgin Islands (U.S.)": "VI",
  "Wallis and Futuna": "WF",
  "Western Sahara": "EH",
  "Yemen": "YE",
  "Zambia": "ZM",
  "Zimbabwe": "ZW",
  "阿富汗": "AF",
  "奥兰群岛": "AX",
  "阿尔巴尼亚": "AL",
  "阿尔及利亚": "DZ",
  "美属萨摩亚": "AS",
  "安道尔": "AD",
  "安哥拉": "AO",
  "安圭拉": "AI",
  "南极洲": "AQ",
  "安提瓜和巴布达": "AG",
  "阿根廷": "AR",
  "亚美尼亚": "AM",
  "阿鲁巴": "AW",
  "澳大利亚": "AU",
  "奥地利": "AT",
  "阿塞拜疆": "AZ",
  "巴哈马": "BS",
  "巴林": "BH",
  "孟加拉国": "BD",
  "巴巴多斯": "BB",
  "白俄罗斯": "BY",
  "比利时": "BE",
  "伯利兹": "BZ",
  "贝宁": "BJ",
  "百慕大": "BM",
  "不丹": "BT",
  "玻利维亚": "BO",
  "波黑": "BA",
  "博茨瓦纳": "BW",
  "布维岛": "BV",
  "巴西": "BR",
  "英属印度洋领地": "IO",
  "文莱": "BN",
  "保加利亚": "BG",
  "布基纳法索": "BF",
  "布隆迪": "BI",
  "柬埔寨": "KH",
  "喀麦隆": "CM",
  "加拿大": "CA",
  "佛得角": "CV",
  "开曼群岛": "KY",
  "中非": "CF",
  "乍得": "TD",
  "智利": "CL",
  "中国": "CN",
  "圣诞岛": "CX",
  "科科斯（基林）群岛": "CC",
  "哥伦比亚": "CO",
  "科摩罗": "KM",
  "刚果（布）": "CG",
  "刚果（金）": "CD",
  "库克群岛": "CK",
  "哥斯达黎加": "CR",
  "科特迪瓦": "CI",
  "克罗地亚": "HR",
  "古巴": "CU",
  "塞浦路斯": "CY",
  "捷克": "CZ",
  "丹麦": "DK",
  "吉布提": "DJ",
  "多米尼克": "DM",
  "多米尼加": "DO",
  "厄瓜多尔": "EC",
  "埃及": "EG",
  "萨尔瓦多": "SV",
  "赤道几内亚": "GQ",
  "厄立特里亚": "ER",
  "爱沙尼亚": "EE",
  "埃塞俄比亚": "ET",
  "福克兰群岛（马尔维纳斯）": "FK",
  "法罗群岛": "FO",
  "斐济": "FJ",
  "芬兰": "FI",
  "法国": "FR",
  "法属圭亚那": "GF",
  "法属波利尼西亚": "PF",
  "法属南部领地": "TF",
  "加蓬": "GA",
  "冈比亚": "GM",
  "格鲁吉亚": "GE",
  "德国": "DE",
  "加纳": "GH",
  "直布罗陀": "GI",
  "希腊": "GR",
  "格陵兰": "GL",
  "格林纳达": "GD",
  "瓜德罗普": "GP",
  "关岛": "GU",
  "危地马拉": "GT",
  "格恩西岛": "GG",
  "几内亚": "GN",
  "几内亚比绍": "GW",
  "圭亚那": "GY",
  "海地": "HT",
  "赫德岛和麦克唐纳岛": "HM",
  "梵蒂冈": "VA",
  "洪都拉斯": "HN",
  "香港": "HK",
  "匈牙利": "HU",
  "冰岛": "IS",
  "印度": "IN",
  "印度尼西亚": "ID",
  "伊朗": "IR",
  "伊拉克": "IQ",
  "爱尔兰": "IE",
  "英国属地曼岛": "IM",
  "以色列": "IL",
  "意大利": "IT",
  "牙买加": "JM",
  "日本": "JP",
  "泽西岛": "JE",
  "约旦": "JO",
  "哈萨克斯坦": "KZ",
  "肯尼亚": "KE",
  "基里巴斯": "KI",
  "朝鲜": "KP",
  "韩国": "KR",
  "科威特": "KW",
  "吉尔吉斯斯坦": "KG",
  "老挝": "LA",
  "拉脱维亚": "LV",
  "黎巴嫩": "LB",
  "莱索托": "LS",
  "利比里亚": "LR",
  "利比亚": "LY",
  "列支敦士登": "LI",
  "立陶宛": "LT",
  "卢森堡": "LU",
  "澳门": "MO",
  "前南马其顿": "MK",
  "马达加斯加": "MG",
  "马拉维": "MW",
  "马来西亚": "MY",
  "马尔代夫": "MV",
  "马里": "ML",
  "马耳他": "MT",
  "马绍尔群岛": "MH",
  "马提尼克": "MQ",
  "毛利塔尼亚": "MR",
  "毛里求斯": "MU",
  "马约特": "YT",
  "墨西哥": "MX",
  "密克罗尼西亚联邦": "FM",
  "摩尔多瓦": "MD",
  "摩纳哥": "MC",
  "蒙古": "MN",
  "黑山": "ME",
  "蒙特塞拉特": "MS",
  "摩洛哥": "MA",
  "莫桑比克": "MZ",
  "缅甸": "MM",
  "纳米比亚": "NA",
  "瑙鲁": "NR",
  "尼泊尔": "NP",
  "荷兰": "NL",
  "荷属安的列斯": "AN",
  "新喀里多尼亚": "NC",
  "新西兰": "NZ",
  "尼加拉瓜": "NI",
  "尼日尔": "NE",
  "尼日利亚": "NG",
  "纽埃": "NU",
  "诺福克岛": "NF",
  "北马里亚纳": "MP",
  "挪威": "NO",
  "阿曼": "OM",
  "巴基斯坦": "PK",
  "帕劳": "PW",
  "巴勒斯坦": "PS",
  "巴拿马": "PA",
  "巴布亚新几内亚": "PG",
  "巴拉圭": "PY",
  "秘鲁": "PE",
  "菲律宾": "PH",
  "皮特凯恩": "PN",
  "波兰": "PL",
  "葡萄牙": "PT",
  "波多黎各": "PR",
  "卡塔尔": "QA",
  "留尼汪": "RE",
  "罗马尼亚": "RO",
  "俄罗斯联邦": "RU",
  "卢旺达": "RW",
  "圣赫勒拿": "SH",
  "圣基茨和尼维斯": "KN",
  "圣卢西亚": "LC",
  "圣皮埃尔和密克隆": "PM",
  "圣文森特和格林纳丁斯": "VC",
  "萨摩亚": "WS",
  "圣马力诺": "SM",
  "圣多美和普林西比": "ST",
  "沙特阿拉伯": "SA",
  "塞内加尔": "SN",
  "塞尔维亚": "RS",
  "塞舌尔": "SC",
  "塞拉利昂": "SL",
  "新加坡": "SG",
  "斯洛伐克": "SK",
  "斯洛文尼亚": "SI",
  "所罗门群岛": "SB",
  "索马里": "SO",
  "南非": "ZA",
  "南乔治亚岛和南桑德韦奇岛": "GS",
  "西班牙": "ES",
  "斯里兰卡": "LK",
  "苏丹": "SD",
  "苏里南": "SR",
  "斯瓦尔巴岛和扬马延岛": "SJ",
  "斯威士兰": "SZ",
  "瑞典": "SE",
  "瑞士": "CH",
  "叙利亚": "SY",
  "台湾": "TW",
  "塔吉克斯坦": "TJ",
  "坦桑尼亚": "TZ",
  "泰国": "TH",
  "东帝汶": "TL",
  "多哥": "TG",
  "托克劳": "TK",
  "汤加": "TO",
  "特立尼达和多巴哥": "TT",
  "突尼斯": "TN",
  "土耳其": "TR",
  "土库曼斯坦": "TM",
  "特克斯和凯科斯群岛": "TC",
  "图瓦卢": "TV",
  "乌干达": "UG",
  "乌克兰": "UA",
  "阿联酋": "AE",
  "英国": "GB",
  "美国": "US",
  "美国本土外小岛屿": "UM",
  "乌拉圭": "UY",
  "乌兹别克斯坦": "UZ",
  "瓦努阿图": "VU",
  "委内瑞拉": "VE",
  "越南": "VN",
  "英属维尔京群岛": "VG",
  "美属维尔京群岛": "VI",
  "瓦利斯和富图纳": "WF",
  "西撒哈拉": "EH",
  "也门": "YE",
  "赞比亚": "ZM",
  "津巴布韦": "ZW"
}
