import {predictRepresentation, representationData, taskOptionsData} from "@/constant/data";
import axios from "axios";
import {Message} from "element-ui";
const randomize = require('randomatic');

export function flattenArray(obj) {
    let flattened = {};
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (Array.isArray(obj[key])) {
                for (let i = 0; i < obj[key].length; i++) {
                    flattened[`${key}.${i}`] = obj[key][i];
                }
            } else {
                flattened[key] = obj[key];
            }
        }
    }
    return flattened;
}

// 分析结果中拿到的Representation和task都是小写的需要获取正常名称
export const getRepresentationLabel = (representation, data=representationData, judge) => {
    const allRepresentData = data.map(item => item.children).flat()
    const label = allRepresentData.find(item => {
        return item.value.toUpperCase() === representation.toUpperCase()
    })?.label
    if (judge) {
        // 只是为了判断是否属于Representation表征
        return !!label
    }
    // 不是为了判断的话如果找不到就直接返回representation
    return label || representation
}
// 应用于结果页面中展示task。
export const getTaskLabel = (task) => {
    const allRepresentData = taskOptionsData.map(item => item.children).flat()
    return allRepresentData.find(item => item.value === task)?.label || task
}
// click here 中从题目中的property和task中得到 在 Form 中需要的 Tasks 的值 [property, task]
export const getTasksValueInForm = (property, task) => {
    // 此处的property和task都是大写的，在click here题目中可以看到
    console.log(property, task)
    const item = taskOptionsData.find(item => item.label === property)
    const taskValue = item.children.find(item => item.label === task)
    if (taskValue && item) {
      return [item.value, taskValue.value]
    }
}

// 判断结果文件获取到的listKey是属于representation和model（用于prediction流程）
export const isRepresentationOrModel = (listKey) => {
    // SVM 属于 modelOptionsData 中的值
    // ECFP2 属于 predictRepresentation 中的值
    return getRepresentationLabel(listKey, predictRepresentation, true) // 是的话为true，否则为false即为model
}
// 主要结果表格的展示关于第0列可能为smiles或者普通列，需要暂时添加判断
// content一般为：第0列的第一个内容
export const isSmiles = (content) => {
    // 正则表达式匹配分子式的模式:一个大写字母后跟零个或一个小写字母
    const elementSymbolPattern = /[A-Z][a-z]?/;
    // 测试输入字符串是否与模式匹配
    return elementSymbolPattern.test(content);
}
// 检验smiles
export const validateMessage = async (smiles, file) => {
    try {
        if (smiles) {
            let {data} = await axios.post('/biobank/public/api/NxzxsNxaQBG8RakCVPJgBK', {
                "params": {},
                "bodyData": {
                    "smile_str": smiles
                }
            })
            if (data.data) return data.data
            else {
                Message({
                    showClose: true,
                    message: 'The SMILES is incorrect',
                    type: 'error'
                })
                return false
            }
        }
        if (file) {
            let smileFile = ''
            // 进行路径的拼接从而传递给后端一个合适的路径
            if (file.root === 'share') {
                smileFile = `/share/${file.name}`
            } else {
                const groupId = '66ab0078471c4e00292dede9' // 小分子团队的groupId
                smileFile = `/${groupId}/${file.root}/${file.name}`
            }
            let { data } = await axios.post('/biobank/public/api/CidToPSc7JYXg1RaonhR6T', {
                "params": {},
                "bodyData": {
                    "smile_file": smileFile
                }
            })
            if (data.data.length === 0) { // 检验成功输出的data是[]
                Message({
                    showClose: true,
                    message: 'SMILES is Validate',
                    type: 'success'
                })
                return true
            }
            else {
                let str = ''
                data.data.forEach(item => { // 检验失败输出的data是['错误的SMILES']
                    str += `<p>${item}</p>`
                })
                Message({
                    showClose: true,
                    dangerouslyUseHTMLString: true,
                    message: str,
                    type: 'error'
                })
                return false
            }
        }
    } catch (e) {
        Message({
            showClose: true,
            message: 'Validation has Error',
            type: 'error'
        })
        return false
    }
}
/**
 * input失去焦点的时候进行校验smiles表达式
 * @returns {Promise<void>}
 */
export const validateSmiles = async (smiles) => {
    if (smiles) {
        await validateMessage(smiles)
    }
}
/**
 * 前端进行对email的格式的校验
 * @param email
 */
export const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    if (email) {
        const isValidate = emailRegex.test(email)
        if (!isValidate) {
            Message({
                showClose: true,
                message: 'Please enter a valid email address',
                type: 'error'
            })
            return false
        }
    }
    return true
}
// 读取示例文件为formData
export const readAsFormData = async (path) => {
    // 从服务器下载文件内容
    const fileRes = await axios.get(path, {
        responseType: 'blob'
    });
    const fileBlob = new Blob([fileRes.data], {type: 'application/octet-stream'});
    const file = new File([fileBlob], 'example file (BACE).csv', {type: 'application/octet-stream'});
    const formData = new FormData();
    formData.append('file', file);
    return formData
}
export const download = (blob, fileName, type) => {
    // 创建一个隐藏的 <a> DOM 元素
    const link = document.createElement('a');
    link.href = type === 'zip' ? URL.createObjectURL(blob) : blob;
    link.download = type === 'zip' ? `${fileName}.zip` : fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
}
    // 获取随机数
export const generateRandomId = () => {
    // 调用 randomatic 生成密码
    return randomize("Aa0", 24);
}

export const parseUrlString = (urlString, key) => {
  const query = {};
  const pairs = (urlString[0] === '?' ? urlString.substr(1) : urlString).split('&');

  for (let pair of pairs) {
    if (!pair) continue;
    const [key, ...rest] = pair.split('=');
    const value = rest.join('=');
    query[decodeURIComponent(key)] = decodeURIComponent(value || '');
  }

  return query[key];
}
/**
 * 添加到访问统计表中
 * @param {*} params 
 */
export const saveSpceIpRecords = async (params) => {
    axios.post('https://ip.app.bio-it.tech/spaceIPRecords/save', {
        "websiteTag": "6761647eff7c5400226eaa58",
        "params": params,//提交的表单数据，使用JSON.stringify来序列化对象
        "language": "en"//如果不填写则以国家名称为中文，en : 表示英文
    })
}