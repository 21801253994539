<script>
import ViewCounter from "@/components/ViewCounter.vue";
export default {
  name: "FooterView",
  components: {
    ViewCounter
  }
}
</script>

<template>
  <div class="footer-container">
    <el-footer class="footer">
      <img src="@/assets/amor.png" alt="描述图片内容" class="logo" />
      <div class="text">
        <span>Copyright © {{new Date().getFullYear()}}，All Rights Reserved by Group He of Xiaochen Bo's Lab.</span>
        <span>Contact us</span>
        <span>Reference</span>
        <!-- <a href="https://info.flagcounter.com/DxLx" target="_blank" v-show="$route.path === '/home'">
          <img src="https://s01.flagcounter.com/count/DxLx/bg_b4a1d5/txt_3F4760/border_b4a1d5/columns_3/maxflags_6/viewers_0/labels_0/pageviews_0/flags_0/percent_0/" alt="Flag Counter" border="0">
        </a> -->
  <!--      <a>-->
  <!--        <view-counter/>-->
  <!--      </a>-->
      </div>
    </el-footer>
    <div v-show="$route.path === '/home'" class="viewCounter">
      <view-counter :counterWidth="12"/>
    </div>
  </div>
</template>

<style scoped lang="less">
.footer-container {
  width: 99.99%;
  height: 3.18rem !important;
  position: absolute;
  left: 0;
  bottom: 0;
  text-align: center;
}
.footer {
  // line-height: 1.36rem;
  display: flex;
  justify-content: space-between;
  column-gap: 10%;
  // background: transparent;
  height: 100% !important;
  align-items: flex-end;
  padding: 0 2rem 1.5rem 2rem;
  font-family: "Arial", "Times New Roman", "Microsoft YaHei", sans-serif;
  // width: 100%;
  span {
    font-size: 0.81rem;
    line-height: 1.09rem;
    color: #3F4760;
    font-weight: bold;
    cursor: pointer;
  }
  .logo {
    width: 2.73rem;
    height: 3.18rem;
    object-fit: contain;
    margin-right: 1rem;
  }
  .text {
    //width: 28.95rem;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    span {
      margin-left: 2rem;
    }
  }
}
a {
  margin-bottom: -0.2rem;
  margin-left: 2rem;
  img {
    height: 3rem;
    object-fit: contain;
  }
}
.viewCounter {
  position: absolute;
  right: calc(50% - 31rem - 12rem);
  bottom: 1.2rem;
}
@media (min-width: 1000px) {
  .footer {
    width: 62rem;
    margin: 0 auto;
  }
}
@media (max-width: 1439px) {
  .viewCounter {
    position: absolute;
    right: 0;
    bottom: 4rem;
  }
}
</style>
