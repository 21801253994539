<template>
  <div class="counter" :style="{width: counterWidth + 'rem'}">
    <div v-for="item in counterList" :key="item.id" class="counter-item">
      <img :src="item.img" alt="" :style="itemStyle" />
      <span>{{ item.count }}</span>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {codes} from "@/utils/codes";

export default {
  name: 'ViewCounter',
  data() {
    return {
      counterList: [],
    }
  },
  props: {
    counterWidth: {
      type: Number,
      default: 15
    },
    itemStyle: {
      type: Object,
      default: () => ({
        width: '1.2rem',
        height: '0.75rem'
      })
    }
  },
  mounted() {
    this.getCounters()
  },
  methods: {
    getCounters() {
      axios.post('space/getCount?token=Nz3JWhcL6Mh61YXQL2ovVb', {
        "filterOption": {
          "filters": {
            "workflow": "5ea244e677ec052aa7a49edf",
            "submission.website": "6761647eff7c5400226eaa58"
          },
          "group": {
            "type": "count", // ['count','avg','sum','stdDevPop','stdDevSamp','max','min']
            "_id": "submission.area" // 以哪个字段分组 值为null不分组
          }
        }
      }).then(({data}) => {
        if (!data.hasError) {
          data.results.forEach(item => {
            const twoLetters = codes[item._id].toLowerCase();
            // 确保 twoLetters 存在并且是有效的字符串
            if (twoLetters) {
              // 如果对象中没有该两字母代码，则初始化为0
              const index = this.counterList.findIndex(i => i.id === twoLetters)
              if (index === -1) {
                this.counterList.push({
                  id: twoLetters,
                  count: item.count,
                  img: require(`@/assets/svg/${twoLetters}.svg`)
                })
              } else {
                // 如果对象中已有该两字母代码，则累加计数
                this.counterList[index].count += item.count;
              }
            }
          });
          // this.counterList.push(...[
          //   {
          //     id: 'AO',
          //     count: 11000000000,
          //     img: require('@/assets/svg/ao.svg')
          //   },
          //   {
          //     id: 'CO',
          //     count: 1,
          //     img: require('@/assets/svg/co.svg')
          //   },
          //   {
          //     id: 'CU',
          //     count: 1,
          //     img: require('@/assets/svg/cu.svg')
          //   },
          // ])
          this.counterList.sort((a, b) => b.count - a.count)
          // this.counterList = this.counterList.slice(0, 9)
        }
      })
    }
  },
}
</script>
<style scoped lang="less">
.counter {
  min-height: 2.5rem;
  background-color: transparent;
  // display: flex;
  // align-items: center;
  // flex-wrap: wrap;
  display: grid;
  grid-template-columns: auto auto auto;

}
.counter-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  min-width: 3rem;
  height: 1.1rem;
  img {
    width: 1.35rem;
    height: 0.8rem;
  }
  span {
    font-size: 0.65rem;
    line-height: 1rem;
    padding-left: 0.3rem;
    color: #615586;
  }
}
</style>
