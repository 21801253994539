<script>
import axios from "axios";
export default {
  name: "TreeCharts",
  props: ['representationName'],
  data() {
    return {
      treeData: [],
      tableData: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      treeExpandData: [
        'SMILES',
        // 'Molecular Fingerprint',
        // 'Molecular Descriptor',
        // 'Molecular Graph',
        // 'Molecular Image',
        // 'geometry',
        // 'Hybrid Representations'
        'Molecular Graph+Geometry'
      ],
      currentNode: 'SELFIES', // 默认显示的节点
    }
  },
  async mounted() {
    await this.getFileDataToTreeData()
    const query = this.$route.query
    if (Object.keys(query).length) {
      if (!this.treeExpandData.includes(query.name)) {
        this.treeExpandData.push(query.name)
      }
      this.currentNode = query.name
      this.handleDefaultName(this.currentNode, this.tableData)
    }
  },
  watch: {
    // representationName: {
    //   handler(newVal) {
    //     console.log(newVal)
    //     if (Object.keys(newVal).length){
    //       // this.handleNodeClick(newVal)
    //       this.currentNode = newVal.label
    //       this.handleDefaultName(this.currentNode, this.tableData)
    //     }
    //   }
    // }
  },
  methods: {
    handleNodeClick(data) {
      if (!data.children) {
        this.$refs.tree.setCheckedNodes([data])
      }
      this.$emit('node-click', data)
    },
    async getFileDataToTreeData() {
      // /biobank/reportTemplate/5e7ac090233167dcfccd9031/group/dashboard/AMOR/benchmark
      const res = await axios.get('/biobank/reportTemplate/5e7ac090233167dcfccd9031/group/dashboard/AMOR/benchmark/benchmark_OE_update.csv')
      this.tableData = this.parseCSV(res.data)
      // 默认显示的节点
      this.handleDefaultName(this.currentNode, this.tableData)
      this.treeData = await this.convertToTree(this.tableData)
    },
    parseCSV(data) {
      // console.log(data)
      // const csv = data.trim().split('\n').map(row => row.split(',').map(cell => cell.trim()))
      // 解析 CSV 的每一行，并正确处理被双引号包裹的字段
      const csv = data.trim().split('\n').map(row => {
        const cells = [];
        let inQuotes = false;
        let cell = '';
        // 当出现段落中有逗号时，此时会被""包裹，所以需要处理
        for (let char of row) {
          if (char === '"') {
            inQuotes = !inQuotes;  // Toggle the inQuotes flag
          } else if (char === ',' && !inQuotes) {
            cells.push(cell.trim());
            cell = '';  // Start a new cell
          } else {
            cell += char;  // Add the character to the current cell
          }
        }
        cells.push(cell.trim());  // Add the last cell
        return cells;
      })
      const headers = csv[0]
      const csvData = csv.slice(1)
      const lineMap = new Map();
      return csvData.map(line => {
        // 更新 lineMap 中的值，只有当 index < 2 时才更新
        // 三级菜单（第三级是绝对有数据的）
        for (let index = 0; index < 2; index++) {
          if (line[index]) lineMap.set(index, line[index]);
        }
        const obj = {};
        headers.forEach((header, index) => {
          obj[header] = line[index] || lineMap.get(index) || ''
        });
        return obj
      })
    },
    async convertToTree(data) {
      const tree = {};
      for (const item of data) {
        const {one, two, three} = item
        if (!tree[one]) {
          tree[one] = {
            name: one,
            label: one,
            children: []
          };
        }
        if (!tree[one].children[two]) {
          tree[one].children[two] = {
            name: two,
            label: two,
            children: []
          };
        }
        if (!tree[one].children[two].children[three]) {
          tree[one].children[two].children[three] = {
            name: three,
            label: three,
            description: item.description,
            picture: item.picture,
            regressionUrl: item.regressionTask,
            regressionTask: [],
            classificationTask: [],
            classificationUrl: item.classificationTask,
            reference: item.reference
          };
        }
      }
      Object.values(tree).forEach(item => {
        item.children = Object.values(item.children)
        item.children.forEach(child => {
          child.children = Object.values(child.children)
        })
      })
      return Object.values(tree)
    },
    handleDefaultName(currentNode, tableData) {
      const item = tableData.find(item => item.three === currentNode)
      this.handleNodeClick({
        ...item,
        regressionUrl: item.regressionTask,
        regressionTask: [],
        classificationTask: [],
        classificationUrl: item.classificationTask,
        reference: item.reference
      })
    }
  }
}
</script>

<template>
<div class="tree_container">
  <el-tree
      :data="treeData"
      :props="defaultProps"
      accordion
      :default-expanded-keys="treeExpandData"
      :default-checked-keys="[currentNode]"
      :highlight-current="true"
      node-key="name"
      ref="tree"
      @node-click="handleNodeClick">
  </el-tree>
</div>
</template>

<style scoped lang="less">
.tree_container {
  min-width: 14.45rem;
  .el-tree {
    background-color: transparent;
  }
  /deep/ .el-tree>.el-tree-node:after {
    border-top: none;
  }
  /deep/ .el-tree-node {
    position: relative;
    padding-left: 0.73rem;
  }
  /deep/ .el-tree-node__expand-icon.is-leaf {
    display: none;
  }
  /deep/ .el-tree-node__children {
    padding-left: 0.73rem;
  }
  /deep/ .el-tree-node :last-child:before {
    height: 1.72rem;
  }
  /deep/ .el-tree>.el-tree-node:before {
    border-left: none;
  }
  /deep/ .el-tree>.el-tree-node:after {
    border-top: none;
  }
  /deep/ .el-tree-node:before {
    content: "";
    left: -0.182rem;
    position: absolute;
    right: auto;
    border-width: 1px;
  }
  /deep/ .el-tree-node:after {
    content: "";
    left: -0.182rem;
    position: absolute;
    right: auto;
    border-width: 0.1rem;
  }
  /deep/ .el-tree-node:before {
    border-left: 0.1rem solid #3F476080;
    bottom: 0;
    //height: 200% !important;
    top: -0.82rem;
    width: 0.1rem;
  }
  /deep/ .el-tree-node:after {
    border-top: 0.1rem solid #3F476080;
    height: 0.91rem;
    top: 0.91rem;
    width: 0.91rem;
  }
  /deep/ .el-tree-node__content {
    background-color: #b39fd5;
    color: #000000;
    margin: 0.23rem 0;
    padding: 0.22rem 0.4rem;
    border-radius: 0.18rem;
    white-space: wrap;
    height: 1.45rem;
  }
  /deep/ .el-tree-node:focus>.el-tree-node__content {
    border: 0.1rem solid #000000;
  }
  /deep/ .el-tree-node__expand-icon {
    color: #5d21e4;
  }
  /deep/ .el-tree-node__children .el-tree-node__content {
    background: #AA95D180;
    box-shadow: 0 2px 3px -1px #61558680 inset;
    padding-left: 0.6rem !important;
  }
  /deep/ .el-tree-node__children .el-tree-node__children .el-tree-node__content {
    background-color: #e5def4;
  }
  /deep/ .el-tree .el-tree-node.is-checked .el-tree-node__content {
    background-color: #f5f7fa;
    border: 0.1rem solid #3F476080
  }
  /deep/ .el-tree-node__label {
    font-size: 0.81rem;
    color: #615586;
  }
  /deep/ .el-tree-node__expand-icon {
    font-size: 0.6rem;
    padding: 0.3rem;
  }
}
</style>
