<script>
import data from "@/views/home/home";
import router from "@/router";
export default {
  name: "HeaderView",
  data() {
    return {
      activeIndex: '1', // 活动菜单项的索引
      menus: data.menus, // 菜单数据
    };
  },
  watch: {
    // 监听路由变化
    $route(to) {
      this.updateActiveIndex(to);
    }
  },
  created() {
    // 组件创建时，初始化活动菜单项
    this.updateActiveIndex(this.$route);
  },
  methods: {
    router() {
      return router
    },
    // 更新活动菜单项的索引
    updateActiveIndex(route) {
      const menu = this.menus.find(menu => menu.route && menu.route.name === route.name) ||
          this.menus.flatMap(menu => menu.submenu || []).find(subItem => subItem.route && subItem.route.name === route.name);
      if (menu) {
        this.activeIndex = menu.index;
      } else if (route.name === 'propertyResult') {
        this.activeIndex = "2"
      } else if (route.name === 'GenerationResult') {
        this.activeIndex = "3"
      }
    },
    // 处理主菜单点击事件
    handleMenuClick(menu) {
      if (menu.route && this.$route.name !== menu.route.name) {
        this.$router.push(menu.route);
      }
    },
    // 处理子菜单点击事件
    handleSubMenuClick(subItem) {
      if (subItem.route && this.$route.name !== subItem.route.name) {
        this.$router.push(subItem.route);
      }
    }
  }
}
</script>

<template>
  <el-header height="3.09rem" class="header">
    <img src="@/assets/amor.png" alt="描述图片内容" class="logo" />
    <div class="nav">
      <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal">
        <template v-for="menu in menus">
          <el-menu-item :key="menu.index" :index="menu.index" v-if="!menu.submenu" @click="handleMenuClick(menu)">{{menu.title}}</el-menu-item>
          <el-submenu v-else :key="menu.index" :index="menu.index">
            <template slot="title">
              <span class="submenu-title">{{ menu.title }}</span>
            </template>
            <el-menu-item v-for="sub in menu.submenu" :key="sub.index" :index="sub.index" @click="handleSubMenuClick(sub)">{{sub.title}}</el-menu-item>
          </el-submenu>
        </template>
      </el-menu>
    </div>
  </el-header>
</template>

<style scoped lang="less">
.el-header {
  display: flex;
  justify-content: space-around;
  overflow: hidden;

/*  padding: 0 0 0 5.09rem;*/
}
@media (min-width: 1100px) {
  .el-header {
    justify-content: space-between;
    padding: 0 0 0 1.73rem;
  }
}
.logo {
  width: 2.59rem;
  height: 3.09rem;
/*  margin-right: 3.41rem;*/
}

.el-menu {
  background: transparent;
  text-decoration: none;
  display: flex;
  align-items: flex-end;
}

li.el-menu-item {
  font-size: 1rem;
  padding: 0;
  margin: 0 0.5rem;
}

.el-menu-demo .el-menu-item:hover,
.el-menu-demo .el-menu-item:hover * {
  background-color: transparent !important;
}
.submenu-title {
  font-size: 1rem;
/*  line-height: 1rem;*/
}
.nav .el-menu-demo .el-menu-item,
.nav .el-menu-demo .submenu-title {
  color: #615586 !important;
  font-weight: 700;
}
.nav .el-menu-demo .el-menu-item:hover,
.nav .el-menu-demo .el-menu-item:focus,
.nav .el-menu-demo .el-menu-item:active,
/deep/ .nav .el-menu-demo .el-submenu__title:hover,
.nav .el-menu-demo .el-submenu__title:focus,
.nav .el-menu-demo .el-submenu__title:active,
.nav .el-menu-demo .submenu-title:hover,
.nav .el-menu-demo .submenu-title:focus,
.nav .el-menu-demo .submenu-title:active {
  background-color: transparent !important;
}
.el-menu-demo {
  height: 3.09rem;
  font-size: 1rem;
}
.el-menu--popup .el-menu-item {
  height: 1.5rem !important;
  font-size: 0.8rem;
  line-height: 1.5rem !important;
  color: #615586 !important;
  font-weight: bold;
  &:hover {
    color: #000000 !important;
  }
  &.is-active {
    color: #000000!important;
  }
/*  height: 45px !important;
  line-height: 45px !important;
  font-size: 1rem;*/
/*  color: #615586 !important;*/
}
.el-menu.el-menu--horizontal {
  border-bottom: none;
}
.el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: 0.136rem solid #615586;
  color: #000000;
}
/deep/ .el-menu--horizontal .el-menu .el-submenu__title {
  font-size: 0.82rem;
  color: #615586;
}
/deep/ .el-menu--horizontal>.el-submenu .el-submenu__title {
  margin: 0 0.5rem;
  padding: 0;
}
/deep/ .el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
  border-bottom: 0.136rem solid #615586;
}
/deep/ .el-menu--horizontal>.el-submenu .el-submenu__icon-arrow {
  margin-top: 0;
  display: none;
}
@media (min-width: 1100px) or (max-width: 1100px) {
  /deep/ .el-menu--horizontal>.el-submenu .el-submenu__title {
    height: 1.1rem;
    line-height: 0.5rem;
    margin: 0 0.5rem;
    padding: 0;
  }
  /deep/ .el-menu--horizontal > .el-menu-item,
  /deep/ .el-menu--horizontal > .el-submenu {
    height: 1.1rem;
    line-height: 0.5rem;
  }
}
@media (max-width: 1065px) {
  /deep/ .el-menu--horizontal > .el-submenu .submenu-title{
    margin-bottom: 0.136rem;
    display: block;
  }
}
@media (min-width: 1100px) {
  .oe-page .el-header, .other-page .el-header {
    width: 62rem;
    box-sizing: border-box;
    margin: 0 auto;
    //padding: 0 1.3rem;
    padding: 0 0.5rem 0 2.2rem; /* 在其他页面的header.vue调整与首页的导航栏要保持一致 */
    justify-content: space-between;
  }
}
/* 在大于1000px和小于1100px的屏幕下header没有和其他页面保持一致 */
@media (max-width: 1100px) and (min-width: 1000px) {
  .home-container .el-header {
    padding: 0 1.95rem;
  }
}
</style>
