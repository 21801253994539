<template>
  <div id="app">
    <!-- 用于渲染匹配的路由组件 -->
    <LayoutView />
  </div>
</template>

<script>
import LayoutView from "@/layout/LayoutView.vue";

export default {
  components: {LayoutView},
  async mounted() {
    await this.loadScript('/jsme/jsme.nocache.js')
    this.loadImages()
  },
  methods: {
    /**
     * 动态加载外部脚本文件 然后添加到head中
     * @param src
     * @returns {Promise<unknown>}
     */
    async loadScript(src) {
      return new Promise(function (resolve, reject) {
        let shouldAppend = false;
        let el = document.querySelector('script[src="' + src + '"]');
        if (!el) {
          el = document.createElement('script');
          el.type = 'text/javascript';
          el.async = true;
          el.src = src;
          shouldAppend = true;
        }
        else if (el.hasAttribute('data-loaded')) {
          resolve(el);
          return;
        }

        el.addEventListener('error', reject);
        el.addEventListener('abort', reject);
        el.addEventListener('load', function loadScriptHandler() {
          el.setAttribute('data-loaded', true);
          resolve(el);
        });

        if (shouldAppend) document.head.appendChild(el);
        console.log(typeof window, 'loadScript')
      });
    },
    /**
     * 预加载图片
     */
    loadImages() {
      const imageUrls = [
        require(`@/assets/results/Figure2.png`),
        require(`@/assets/results/Figure3.png`),
        require(`@/assets/results/Figure4.png`),
        require(`@/assets/results/Figure5.png`),
      ]
      imageUrls.forEach(url => {
        const img = new Image();
        img.src = url;
      });
    }
  }
};
</script>
<style scoped lang="less">

</style>
